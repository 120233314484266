/* You can add global styles to this file, and also import other style files */
// @import '~bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

// @import url("https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css");
// @import url("https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css");
// @import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700");

// @import "~admin-lte/plugins/fontawesome-free/css/all.min.css";
// @import "~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css";
// @import "~admin-lte/dist/css/adminlte.min.css";

// @import "~ngx-toastr/toastr";
// @import "~bootstrap/dist/css/bootstrap.css";

@import "~@ng-select/ng-select/themes/default.theme.css";



body {
    width: 100%;
    height: 100vh;
}

/* width */
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #000;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #000;
  }

.content {
    padding: 0 0.5rem;
}

// Sidebar menu animation
// Needed for open/close menu animation for menus with child nodes
.nav .nav-item:not(.menu-open) > .ng-trigger-openClose,
.nav .nav-item > .ng-trigger-openClose.ng-animating {
    overflow: hidden;
    display: block;
}
.bgParpale {
    background-color: #2a0952 !important;
}
// Override original AdminLTE transform
.nav-sidebar .menu-is-opening > .nav-link i.right,
.nav-sidebar .menu-is-opening > .nav-link svg.right,
.nav-sidebar .menu-open > .nav-link i.right,
.nav-sidebar .menu-open > .nav-link svg.right {
    transform: none;
}
.navbar {
    height: 100px !important;
}
.sidebar {
    box-shadow: 15px 0px 15px -10px #e7973b;
}
.main-header {
    border-bottom: 2px solid #e7973b;
}

/* Importing Bootstrap SCSS file. */
// @import "~bootstrap/scss/bootstrap";

// /* Importing Datepicker SCSS file. */
// @import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}
.ui-theme-settings .btn-open-options {
    border-radius: 50px;
    position: absolute;
    left: -60px;
    bottom: 25px;
    padding: 0;
    height: 27px;
    line-height: 28px;
    width: 27px;
    text-align: center;
    display: block;
    box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%), 0 0.9375rem 1.40625rem rgb(4 9 20 / 3%),
        0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
    margin-top: -27px;

    .fa-2x {
        font-size: 1rem;
    }
}
.btn-warning {
    color: #212529;
    background-color: #d9d8d6;
    border-color: #262626;
}

.card-header {
    padding: 3px 1.25rem !important;
    height: 37px !important;
}

::ng-deep.ngx-pagination {
    margin-left: 0;
    margin-bottom: 1rem;
    /* position: absolute; */
    width: 1000px !important;
    /* bottom: -36px; */
    margin: auto;
    text-align: center !important;
    height: 43px !important;
    margin-top: 50px !important;
}

.app-main .app-main__inner {
    padding: 30px 0px 0 !important;
    flex: 1;
}

.modal-backdrop {
    z-index: 999 !important;
}

.table {
    .thead-dark {
        th {
            color: #fff;
            background-color: #485563  !important;
            border-color: #485563 !important ;
        }
    }
}
.buttonEEvent{
    color: #212529;
}
// @import "~@ng-select/ng-select/themes/default.theme.css";


::ng-deep.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{
    font-size: .9em;
    margin-bottom: 5px;
    color: #fff !important;
    background-color: #485563 !important;
    border-radius: 0px !important;
    margin-right: 5px;
}



.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
    
    border-color: var(--vz-border-color);
    background-color: var(--vz-light);
}


.ngx-datatable.material .empty-row {
    height: 85px !important;
    text-align: left !important;
    padding: 10% !important;
    vertical-align: top !important;
    border-top: 0 !important;
    font-weight: bold !important;
    display: flex !important;
    justify-content: center !important;
}

.btn-close{
    border: 0px;
    background: none !important;
    position: absolute;
    right: 16px;
    top: 20px;
 
 .far.fa-times-circle{
   font-size: 0px;
 }   
}
.modal-title{
    color: #fff;
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem 0px !important;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    background: #EB561F ;
    color: white !important;
    .btn-close{
        i{
            color: #fff;
        }
    }
}
.bx{
    color: #EB561F;
    &:hover{
        color: #000000;
    }
}
.ri-rocket-line{
    color: #EB561F;
    &:hover{
        color: #000000;
    }
}
.ri-close-circle-line{
    color: #EB561F;
    &:hover{
        color: #000000;
    }
}
.color-orange{
    color: #EB561F !important;
}
.btn-orange{
    color: #fff !important;
    background-color: #EB561F !important;
    border-color: #EB561F !important;
}
.table-nowrap td, .table-nowrap th {
    white-space: nowrap;
    font-size: 12px;
}
.table td, .table th{
    vertical-align: middle !important;

}
.deleteBx{
    border: 1px solid #EB561F;
    border-radius: 10px;
    padding: 0px;
    display: flex;
    margin: 0px;
    width: 13px;
    height: 13px;
    text-align: center;
    align-items: center;
    justify-content: center;
    &:hover{
        color: #000000;
    }
}

.footer{
    position: fixed !important; 
}

.datatable-scroller {
    width: 100% !important;
}


 
  select   {
    padding-bottom: 20px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M12 18l-6-6h12z"/></svg>');
    background-repeat: no-repeat;
    background-position: right center;
  }


  .donut-chart-label {
    position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    top: 72px;
    /* right: 27px; */
    left: 119px;
   // font-size: 24px;
    color: #000;
    font-size: small;
    // left: 132%;
  }

  .power-generation-gauge{
    transform: translate(44%, -187%);
    position: relative;
  }
  .bs-datepicker {
    display: flex;
    align-items: stretch;
    flex-flow: row wrap;
    background: #fff;
    // box-shadow: 0 0 10px 0 #aaa;
    position: relative;
    z-index: 1;
}

.bs-datepicker:after {
    clear: both;
    content: "";
    display: block;
}

.bs-datepicker button:hover,
.bs-datepicker button:focus,
.bs-datepicker button:active {
    outline: none;
}

.bs-datepicker-head {
    min-width: 270px;
    height: 50px;
    padding: 10px;
    border-radius: 3px 3px 0 0;
    text-align: justify;
}

.bs-datepicker-head:after {
    content: "";
    display: inline-block;
    vertical-align: top;
    width: 100%;
}

.bs-datepicker-head button {
    display: inline-block;
    vertical-align: top;
    padding: 0;
    height: 30px;
    line-height: 30px;
    border: 0;
    background: transparent;
    text-align: center;
    cursor: pointer;
    color: #fff;
    transition: 0.3s;
}

.bs-datepicker-head button[disabled],
.bs-datepicker-head button[disabled]:hover,
.bs-datepicker-head button[disabled]:active {
    background: rgba(221, 221, 221, 0.3);
    color: #f5f5f5;
    cursor: not-allowed;
}

.bs-datepicker-head button.previous span {
    transform: translate(-1px, -1px);
}

.bs-datepicker-head button.next span {
    transform: translate(1px, -1px);
}

.bs-datepicker-head button.next,
.bs-datepicker-head button.previous {
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

.bs-datepicker-head button.next span,
.bs-datepicker-head button.previous span {
    font-size: 28px;
    line-height: 1;
    display: inline-block;
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 50%;
}

.bs-datepicker-head button.current {
    border-radius: 15px;
    max-width: 155px;
    padding: 0 13px;
}

.bs-datepicker-head button:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.bs-datepicker-head button:active {
    background-color: rgba(0, 0, 0, 0.2);
}

.bs-datepicker-body {
    padding: 10px;
    border-radius: 0 0 3px 3px;
    min-height: 232px;
    min-width: 278px;
    border: 1px solid #e9edf0;
}

.bs-datepicker-body .days.weeks {
    position: relative;
    z-index: 1;
}

.bs-datepicker-body table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.bs-datepicker-body table th {
    font-size: 13px;
    color: #9aaec1;
    font-weight: 400;
    text-align: center;
}

.bs-datepicker-body table td {
    color: #54708b;
    text-align: center;
    position: relative;
    padding: 0;
}

.bs-datepicker-body table td span {
    display: block;
    margin: 0 auto;
    font-size: 13px;
    border-radius: 50%;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.bs-datepicker-body table td:not(.disabled):not(.week) span:not(.disabled):not(.is-other-month) {
    cursor: pointer;
}

.bs-datepicker-body table td span.disabled {
    color: #9aaec1;
}

.bs-datepicker-body table td span.is-other-month {
    color: rgba(0, 0, 0, 0.25);
}

.bs-datepicker-body table td:before,
.bs-datepicker-body table td span:before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    top: 6px;
    bottom: 6px;
    left: -3px;
    right: -2px;
    box-sizing: content-box;
    background: transparent;
}

.bs-datepicker-body table.days span {
    width: 32px;
    height: 32px;
    line-height: 32px;
}

.bs-datepicker-container {
    padding: 15px;
}

.bs-datepicker .bs-media-container {
    display: flex;
}

@media (max-width: 768px) {
    .bs-datepicker .bs-media-container {
        flex-direction: column;
    }
}

bs-datepicker-container {
    z-index: 1080;
}

@media (max-width: 768px) {
    .bs-datepicker {
        width: min-content;
        justify-content: center;
    }
}


.theme-green .bs-datepicker-head {
    background-color: #FB8C04;
}

.bs-datepicker .form-control {
    box-shadow: none;
    font-size: 15px;
    color: #555;
    height: 32px !important;
    line-height: 2;
}

.cal-icon {
    position: relative;
    width: 100%;
}

.cal-icon:after {
    color: #979797;
    content: "\f073";
    display: block;
    font-family: "Font Awesome 5 Free";
    font-size: 15px;
    margin: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
}
